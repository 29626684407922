import { Script, graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import ContactSales from "../components/common/ContactSales"
import Faqs from "../components/common/Faqs2"
import SEORevamp from "../components/common/SEO_Revamp"
import QuickReads from "../components/hire-laravel-developers/QuickReads"
import AngularIndustries from "../components/hire-pages-compoents/AngularIndustries"
import Banner from "../components/hire-pages-compoents/Banner"
import Engineering from "../components/hire-pages-compoents/Engineering"
import Combination from "../components/hire-pages-compoents/FullStackCombination"
import HireDevelopers from "../components/hire-pages-compoents/HireDevelopers"
import Maximize from "../components/hire-pages-compoents/Maximize"
import Remote from "../components/hire-pages-compoents/Remote"
import Solutions from "../components/hire-pages-compoents/Solutions"
import SolutionsMobile from "../components/hire-pages-compoents/SolutionsMobile"
import Success from "../components/hire-pages-compoents/Success"
import Empower from "../components/hire-pages-compoents/WhyShould"
import HowHireAngular from "../components/hire-python-developers/HowHireAngular"
import Statics from "../components/hire-typescript/Statics"
import Toolkit from "../components/react-native/Toolkit"
import Finest from "../components/web-application/Opportunity"
import MainLayout from "../layouts/MainLayout"
export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />
      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        schemas={headSchema}
        image="https://invozone-backend.s3.amazonaws.com/leading_react_native_app_development_company_e5e33e137c.webp"
      />
    </>
  )
}
const Frontend = ({ data }) => {
  console.log(data)
  const [isMobile, setIsMobile] = useState(false)
  const [isTablet, setIsTablet] = useState(false)
  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }
  useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const heroSection = data?.strapiPage?.sections[0]
  const whyhire = data?.strapiPage?.sections[1]
  const whyShould = data?.strapiPage?.sections[2]
  const Champs = data?.strapiPage?.sections[3]
  const Steps = data?.strapiPage.sections[4]
  const qualities = data?.strapiPage?.sections[5]
  const hours = data?.strapiPage?.sections[6]
  const combinations = data?.strapiPage?.sections[7]
  const core = data?.strapiPage?.sections[8]
  const Tap = data?.strapiPage?.sections[9]
  const industriesAngular = data?.strapiPage?.sections[10]
  const glimpse = data?.strapiPage?.sections[11]
  const turning = data?.strapiPage?.sections[12]
  const quick = data?.strapiPage?.sections[13]
  const faqs = data?.strapiPage?.sections[14]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  const devSDetails = [
    {
      name: "Zohaib Javed",
      devImage:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/zohaib_dev_f5e92a312e.png",
      experience: "6+ years of experience",
      desc: "Zohaib is a seasoned frontend developer with over 6 years of experience. He excels in creating scalable solutions that satisfy users & encourage business expansion.",
      rating:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/stars_4_8_3cbdc850f0.svg",
      reviews: "<b>4.8</b> Reviews",
      skills: [
        "HTML5",
        "REST APIs",
        "Node.js",
        "React.js",
        "D3.js",
        "Javascript",
        "UIUXDesign",
      ],
    },
    {
      name: "Hamza Sajid",
      devImage:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/hamza_dev_7abcd63f18.png",
      experience: "4+ years of experience",
      desc: "Hamza is a front-end developer who is Proficient in front-end integrations. He's quite passionate about enhancing user experiences with over 2 years of experience.",
      rating:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/stars_4_6_2457829227.svg",
      reviews: "<b>4.8</b> Stars Reviews",
      skills: [
        "React Js",
        " React Native",
        "Javascript",
        "TypeScript",
        "MySQL",
      ],
    },
    {
      name: "Safdar Abbas",
      devImage:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/safdar_dev_9eb469e18b.png",
      experience: "4+ years of experience",
      desc: "Safdar Abbas is an immensely experienced front-end engineer with a very strong background in designing and implementing complex web apps using Angular frameworks.",
      rating:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/stars_4_5_c3048ffdfe.svg",
      reviews: "<b>4.7</b> Stars Reviews",
      skills: ["Angular", "RxJs", "jQuery", "NgRx", "TypeScript"],
    },
  ]

  return (
    <MainLayout schemas={bodySchema}>
      <Banner
        strapiData={heroSection}
        breadCrumb={data?.strapiPage?.buttons}
        pageName="Hire Angular Developers"
        // vueJsCard={true}
        hireFrontend={true}
      />
      <Statics />
      <Engineering strapiData={whyhire} frontendEng={true} />
      <Maximize strapiData={whyShould} />
      <HireDevelopers
        strapiData={Champs}
        devSDetails={devSDetails}
        frontendDec={true}
      />
      <HowHireAngular strapiData={Steps} drupalSteps={true} />
      <Empower strapiData={qualities} Vueidentify={true} />
      <Remote strapiData={hours} hireFront={true} />
      <Combination strapiData={combinations} />
      <Toolkit strapiData={core} backendToolkit={true} />
      <Finest strapiData={Tap} vueFuture={true} vsJsBanner />
      <AngularIndustries strapiData={industriesAngular} VueIndsDec={true} />
      {!isMobile && !isTablet ? (
        <Solutions strapiData={glimpse} />
      ) : (
        <SolutionsMobile strapiData={glimpse} />
      )}
      <Success strapiData={turning} />
      <QuickReads strapiData={quick} />
      <Faqs strapiData={faqs} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query frontend {
    strapiPage(slug: { eq: "hire-frontend-developers" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          title2
          subTitle
          subTitle2
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              publicURL
            }
            url
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default Frontend
